import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: baseline;
    width: 100%;
    font-size: 3.2rem;
    line-height: 3.2rem;
    font-weight: 700;
    flex-wrap: wrap;
`;

export const Value = styled.span.withConfig({
    shouldForwardProp: (prop) => !['crossed'].includes(prop),
})<{
    crossed?: boolean;
}>`
    ${({ theme, crossed }) => css`
        display: inline-flex;
        align-items: flex-end;

        @media ${theme.breakpoints.sm.max} {
            flex-basis: 100%;
        }

        ${crossed &&
        css`
            color: ${theme.palette.color.positive.darken};
            position: relative;
            margin-right: 1.6rem;
            font-size: 2.2rem;

            @media ${theme.breakpoints.sm.max} {
                flex-basis: unset;
                margin-left: unset;
            }

            &:after {
                content: '';
                position: absolute;
                left: -0.5rem;
                right: -0.5rem;
                height: 0.2rem;
                top: 45%;
                background-color: ${theme.palette.color.positive.darken};
                transform: rotate(-4deg);
            }
        `}
    `}
`;

export const Suffix = styled.span`
    ${({ theme }) => css`
        display: inline-flex;
        align-items: flex-end;
        font-size: ${theme.text.size.xlarge.fontSize};
        color: ${theme.palette.color.positive.darken};
    `}
`;

export const Discount = styled.div`
    ${({ theme }) => css`
        font-weight: 700;
        font-size: ${theme.text.size.small.fontSize};
        line-height: ${theme.text.size.small.lineHeight};
        margin-left: 1rem;
    `}
`;

export const WithoutVat = styled.div``;

export const WithVat = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.text.size.xlarge.fontSize};
        color: ${theme.palette.color.positive.darken};
    `}
`;
