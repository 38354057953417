import * as React from 'react';
import { useTranslation } from 'react-i18next';
// import { LookupKeyEnum } from '@app/common';
import { observer } from 'mobx-react-lite';
import { Button } from '../../../../app/form/button/button';
import { useRootStore } from '../../../../../providers/root-store-provider';

type TariffExclusiveButtonProps = {
    period: 'yearly' | 'monthly';
};

export const TariffExclusiveButton: React.FC<TariffExclusiveButtonProps> = observer(() => {
    const { t } = useTranslation();
    const rootStore = useRootStore();
    // const { authStore } = rootStore;
    // const { stripeStore } = rootStore;
    // const isLoading = authStore.loading || stripeStore.loading;

    const loggedUser = rootStore.cookieService.hasJwtToken();

    // const lookupKey = period === 'yearly' ? LookupKeyEnum.ExclusiveYearlyCzk202411 : LookupKeyEnum.ExclusiveMonthlyCzk202411;

    if (loggedUser) {
        // Logged user with active exclusive tariff
        // if (authStore.userHasPremiumTariff) {
        //     return null;
        // }

        return (
            <Button
                // onClick={async () => {
                //     await stripeStore.createCheckoutSession(lookupKey);
                //     await authStore.fetchUserProfile();
                // }}
                // loading={isLoading}
                variant="positive"
                gradient
                shadow
                size="large"
                disabled
            >
                {/* {t('blocks.pricing.exclusive.action.getUnlimited')} */}
                {t('blocks.pricing.exclusive.comingSoon')}
            </Button>
        );
    }

    // Not logged user => Sign up button (= open modal)
    return (
        <Button
            // onClick={() => {
            //     authStore.authDialogStore.setSignupActive(lookupKey);
            // }}
            variant="positive"
            gradient
            shadow
            size="vlarge"
            disabled
        >
            {/* {t('blocks.pricing.exclusive.action.signUp')} */}
            {t('blocks.pricing.exclusive.comingSoon')}
        </Button>
    );
});
