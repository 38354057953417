import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    EXCLUSIVE_SUBSCRIPTION_MONTHLY_PRICE,
    EXCLUSIVE_SUBSCRIPTION_MONTHLY_PRICE_WITH_VAT,
    EXCLUSIVE_SUBSCRIPTION_YEARLY_PRICE,
    EXCLUSIVE_SUBSCRIPTION_YEARLY_PRICE_WITH_VAT,
} from '@app/common/src/constants/exclusive-subscription';
import { Wrapper, Value, Suffix, WithoutVat, WithVat } from './tariff-exclusive-price.styled';
import { ValueString } from '../../../../app/value/string/value-string';

type TariffExclusivePriceProps = {
    period: 'yearly' | 'monthly';
};

export const TariffExclusivePrice: React.FC<TariffExclusivePriceProps> = ({ period }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            {/* {period === 'monthly' && ( */}
            {/*    <Value crossed> */}
            {/*        <ValueString text={'4 457 Kč'} mode={'pure'} locked /> */}
            {/*    </Value> */}
            {/* )} */}
            {/* {period === 'yearly' && ( */}
            {/*    <Value crossed> */}
            {/*        <ValueString text={'3 457 Kč'} mode={'pure'} locked /> */}
            {/*    </Value> */}
            {/* )} */}
            <WithoutVat>
                <Value>
                    <ValueString
                        text={
                            period === 'monthly'
                                ? EXCLUSIVE_SUBSCRIPTION_MONTHLY_PRICE
                                : EXCLUSIVE_SUBSCRIPTION_YEARLY_PRICE
                        }
                        mode={'pure'}
                        locked
                    />
                </Value>
                <Suffix>
                    &nbsp;{t(`blocks.pricing.common.withoutVat`)}&nbsp;/ {t(`blocks.pricing.common.${period}`)}
                </Suffix>
            </WithoutVat>
            <WithVat>
                <Value>
                    <ValueString
                        text={
                            period === 'monthly'
                                ? EXCLUSIVE_SUBSCRIPTION_MONTHLY_PRICE_WITH_VAT
                                : EXCLUSIVE_SUBSCRIPTION_YEARLY_PRICE_WITH_VAT
                        }
                        mode={'pure'}
                        locked
                    />
                </Value>
                <Suffix>
                    &nbsp;{t(`blocks.pricing.common.withVat`)}&nbsp;/ {t(`blocks.pricing.common.${period}`)}
                </Suffix>
            </WithVat>
        </Wrapper>
    );
};
