import * as React from 'react';
import { Wrapper } from './tariff-note.styled';

interface TariffNoteProps {
    children: React.ReactNode;
}

export const TariffNote: React.FC<TariffNoteProps> = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};
