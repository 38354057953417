import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './tariff-exclusive.styled';
import { TariffHeader } from '../header/tariff-header';
import { TariffWrapper } from '../wrapper/tariff-wrapper';
import { TariffBadge } from '../badge/tariff-badge';
import { TariffExclusivePrice } from './price/tariff-exclusive-price';
import { TariffNote } from '../note/tariff-note';
import { TariffExclusiveButton } from './button/tariff-exclusive-button';
import { TariffFeaturesExclusive } from '../features/exclusive/tariff-features-exclusive';

type TariffexclusiveProps = {
    period: 'yearly' | 'monthly';
};

export const TariffExclusive: React.FC<TariffexclusiveProps> = observer(({ period }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <TariffWrapper>
                <TariffHeader
                    name={t(`blocks.pricing.exclusive.name`)}
                    badge={
                        period === 'yearly' ? (
                            <TariffBadge content={t(`blocks.pricing.exclusive.comingSoon`)} />
                        ) : (
                            <TariffBadge content={t(`blocks.pricing.exclusive.comingSoon`)} />
                        )
                    }
                />

                <TariffExclusivePrice period={period} />

                <TariffFeaturesExclusive />

                <TariffExclusiveButton period={period} />

                <TariffNote>{t('blocks.pricing.exclusive.note')}</TariffNote>
            </TariffWrapper>
        </Wrapper>
    );
});
