import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Wrapper } from './tariff-badge.styled';

interface TariffBadgeProps {
    content: string;
}

export const TariffBadge: React.FC<TariffBadgeProps> = observer(({ content }) => {
    return <Wrapper>{content}</Wrapper>;
});
