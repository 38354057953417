import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        border-radius: 2.4rem;
        background-color: var(--tariff-color-light);
        padding: 0.6rem ${theme.gap.vsmall};
        color: var(--tariff-color);
        text-transform: uppercase;
        font-weight: 700;
        font-size: ${theme.text.size.xsmall.fontSize};
        line-height: ${theme.text.size.xsmall.lineHeight};
        white-space: nowrap;

        @media ${theme.breakpoints.lg.min} {
            font-size: ${theme.text.size.small.fontSize};
            line-height: ${theme.text.size.small.lineHeight};
            padding: ${theme.gap.xsmall} ${theme.gap.small};
        }
    `,
);
