import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconMinus: React.FC = () => (
    <Icon viewBox="0 0 10 2" fill="none">
        <path
            d="M1 1h8"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
